import { defineStore } from 'pinia';
import axios from '@/plugins/axios';
import { AxiosResponse } from 'axios';
import type { Order, CreateOrder, CreateOrderDetail } from '@/types'
 import _ from 'lodash'
import { formatShortDateTime } from "@kanaboy/utilities/dist/formatter";
import { useOrderStore } from './useOrderStore';

export const useCreateOrderStore = defineStore("createOrderStore", {
  state: () => ({
    loaded: true,
    dateLoaded: formatShortDateTime(new Date()),
    orders: [] as Order[],
    order: null as CreateOrder | null,
    orderDetail: [] as CreateOrderDetail[],
    editOrderDetail: null as CreateOrderDetail | null,
    indexSelected: -1,
    departmentId: 0,
    note: '',
    departmentName: '',
    status: ''
  }),

  actions: {
    saveOrder() {
      const details = [] as CreateOrderDetail[]
      _.forEach(this.orderDetail, function(d) {
        details.push({
          productId: d.productId,
          productName: d.productName,
          unitName: d.unitName,
          requestedQty: d.requestedQty,
          remainQty: d.remainQty
        })
      })
      const data = {
        DepartmentId: this.departmentId,
        Note: this.note,
        Details: details
      }
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .post("/orders",data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    sendOrder() {
      const details = [] as CreateOrderDetail[]
    _.forEach(this.orderDetail, function(d) {
      details.push({
        productId: d.productId,
        productName: d.productName,
        unitName: d.unitName,
        requestedQty: d.requestedQty,
        remainQty: d.remainQty
      })
    })
      const data = {
        DepartmentId: this.departmentId,
        Note: this.note,
        Details: details
      }
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .post("/orders/createAndSendOrder",data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    editOrder(id: number) {
      const details = [] as CreateOrderDetail[]
      _.forEach(this.orderDetail, function(d) {
        details.push({
          productId: d.productId,
          productName: d.productName,
          unitName: d.unitName,
          requestedQty: d.requestedQty,
          remainQty: d.remainQty
        })
      })
      const data = {
        DepartmentId: this.departmentId,
        Note: this.note,
        Details: details
      }
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .put(`/orders/${id}`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    editAndSendOrder(id: number) {
      const orderStore = useOrderStore()
      const departmentID = orderStore.order?.departmentId
      const details = [] as CreateOrderDetail[]
    _.forEach(this.orderDetail, function(d) {
      details.push({
        productId: d.productId,
        productName: d.productName,
        unitName: d.unitName,
        requestedQty: d.requestedQty,
        remainQty: d.remainQty
      })
    })
      const data = {
        DepartmentId: departmentID,
        Note: this.note,
        Details: details
      }
      console.log(data);
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .put(`/orders/editAndSendOrder/${id}`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getOrder(id: number) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .get(`/Orders/${id}`)
          .then((response) => {
            const { data } = response
            this.order = {
              id: data.id,
              date: data.date,
              documentNumber: data.documentNumber,
              departmentId: data.departmentId,
              departmentName: data.departmentName,
              statusCode: data.statusCode,
              statusName: data.statusName,
              note: data.note,
              details: _.map(data.detail, (x) => {
                return {
                  productId: x.productId,
                  productName: x.productName,
                  requestedQty: x.requestQty,
                  remainQty: x.remainQty,
                  unitName: x.unitName
                } as CreateOrderDetail
              })
            }
            this.note = data.note
            this.orderDetail = _.map(data.detail, (x) => {
              return {
                productId: x.productId,
                productName: x.productName,
                requestedQty: x.requestQty,
                remainQty: x.remainQty,
                unitName: x.unitName
              } as CreateOrderDetail
            })
            this.departmentId = data.departmentId
            this.departmentName = data.departmentName
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  }
});
