import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw, RouteLocationRaw } from "vue-router";
import { useUserStore } from "@/stores/useUserStore";
import { useOrderStore } from "@/stores/useOrderStore";
import { useCreateOrderStore } from "@/stores/useCreateOrderStore";

export interface User {
  id: number;
  firstname: string;
}

export const go2 = (to: RouteLocationRaw) => {
  router.push(to);
};

const routes: Array<RouteRecordRaw> = [
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    name: "home",
    path: "",
    component: () => import("@/views/HomePage.vue"),
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("@/views/OrderPage.vue"),
  },
  {
    name: "order-details",
    path: "/order-details/:id",
    component: () => import("@/views/OrderDetailsPage.vue"),
  },
  {
    name: "pickings",
    path: "/pickings",
    component: () => import("@/views/PickingPage.vue"),
  },
  {
    name: "picking-details",
    path: "/picking-details/:id",
    component: () => import("@/views/PickingDetailsPage.vue"),
  },
  {
    name: "confirm-picking-details",
    path: "/confirm-picking-details/:id",
    component: () => import("@/views/ConfirmPickingDetailsPage.vue"),
  },
  {
    name: "filter-orders",
    path: "/filter-orders",
    component: () => import("@/views/FilterOrderPage.vue"),
  },
  {
    name: "filter-pickings",
    path: "/filter-pickings",
    component: () => import("@/views/FilterPickingPage.vue"),
  },
  {
    name: "create-order",
    path: "/create-order",
    component: () => import("@/views/CreateOrderPage.vue"),
  },
  {
    name: "edit-order",
    path: "/edit-order/:id",
    component: () => import("@/views/EditOrderPage.vue"),
  },
  {
    name: "create-order-detail",
    path: "/create-order-detail",
    component: () => import("@/views/CreateOrderDetailPage.vue"),
  },
  {
    name: "edit-order-detail",
    path: "/edit-order-detail",
    component: () => import("@/views/EditOrderDetailPage.vue"),
  },
  {
    name: "edit-packing-order-detail",
    path: "/edit-packing-order-detail",
    component: () => import("@/views/EditPackingOrderDetailPage.vue"),
  },
  {
    name: "x",
    path: "/x",
    component: () => import("@/views/XPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  if (to.name !== "login" && !userStore.isAuthenticated()) {
    // next();
     next({ name: "login" });
  } else {
    const orderStore = useOrderStore()
    const createOrderStore = useCreateOrderStore()

    switch (to.name) {
      case 'orders':
        if(from.name !== 'filter-orders' && from.name !== 'order-details') {
          orderStore.$patch({
            filterOrderOptions: {
              departmentId: 0,
              statusId: 0,
              pageNumber: 1,
              pageSize: 10,
            }
          })
        }
        if(from.name == 'filter-orders' && orderStore.filterOrderOptions.routeOrderState === 0) {
          orderStore.$patch({
            filterOrderOptions: {
              departmentId: 0,
              statusId: 0,
              pageNumber: 1,
              pageSize: 10,
            }
          })
        }
        await orderStore.getOrders()
        break;
      case 'pickings':
        if(from.name !== 'filter-pickings' && from.name !== 'picking-details') {
          orderStore.$patch({
            filterPickingOptions: {
              departmentId: 0,
              statusId: 0,
              pageNumber: 1,
              pageSize: 10,
            }
          })
        }
        if(from.name == 'filter-pickings' && orderStore.filterPickingOptions.routePickingState === 0) {
          orderStore.$patch({
            filterPickingOptions: {
              departmentId: 0,
              statusId: 0,
              pageNumber: 1,
              pageSize: 10,
            }
          })
        }
        if(from.name == 'filter-pickings' && orderStore.filterPickingOptions.routePickingState === 1) {
          orderStore.$patch({
            filterPickingOptions: {
              pageNumber: 1,
              pageSize: 10,
            }
          })
        }
        await orderStore.getOrderForPickings()
        break;
      case 'picking-details':
        if(from.name != 'edit-packing-order-detail'){
          await orderStore.getOrder(Number(to.params.id))
        }
        break;
      case 'create-order':
          if(from.name == 'orders'){
            createOrderStore.$patch({
              status: 'back',
            })
          }
          else if(from.name == 'home'){
            createOrderStore.$patch({
              status: '',
              orderDetail: [],
              departmentId: 0
            })
          } 
          break;
      case 'edit-order':
            if(from.name == 'order-details'){
              createOrderStore.getOrder(Number(to.params.id))
            }
            break;
    }
    next();
  }
});

export default router;
