import { defineStore } from 'pinia';
import axios from '@/plugins/axios';
import { AxiosResponse } from 'axios';
import type { Order, OrderDetail, UpdateOrderDetailProperties } from '@/types'
import _ from 'lodash'
import { formatShortDateTime } from "@kanaboy/utilities/dist/formatter";

export const useOrderStore = defineStore("OrderStore", {
  state: () => ({
    loaded: true,
    dateLoaded: formatShortDateTime(new Date()),
    orders: [] as Order[],
    filterPickingOptions: {
      departmentId: 0,
      statusId: 0,
      pageNumber: 1,
      pageSize: 10,
      routePickingState: 0,
      departmentTypeId: 0,
    },
    filterOrderOptions: {
      departmentId: 0,
      statusId: 0,
      pageNumber: 1,
      pageSize: 10,
      routeOrderState: 0,
    },
    order: null as Order | null,
    status: '',
    editOrderDetail: null as OrderDetail | null,
    indexSelected: -1,
  }),

  actions: {
    getOrders() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this
      return new Promise<AxiosResponse>((resolve, reject) => {
        this.loaded = false
        axios
          .get("/orders", {
            params: {
              statusId: this.filterOrderOptions.statusId,
              departmentId: this.filterOrderOptions.departmentId,
              pageNumber: this.filterOrderOptions.pageNumber,
              pageSize: this.filterOrderOptions.pageSize,
            },
          })
          .then((response) => {
            const { data } = response
            const { items } = data
            if (vm.filterOrderOptions.pageNumber === 1) {
              vm.orders = []
            }
            _.forEach(items, function (value: Order) {
              vm.orders.push({
                id: value.id,
                date: value.date,
                documentNumber: value.documentNumber,
                departmentName: value.departmentName,
                employeeName: value.employeeName,
                statusCode: value.statusCode,
                statusName: value.statusName,
                note: value.note
              } as Order)
            });
            this.loaded = true
            this.dateLoaded = formatShortDateTime(new Date())
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getOrderForPickings() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this
      return new Promise<AxiosResponse>((resolve, reject) => {
        this.loaded = false
        axios
          .get("/orders/getOrderForPicking", {
            params: {
              statusId: this.filterPickingOptions.statusId,
              departmentId: this.filterPickingOptions.departmentId,
              pageNumber: this.filterPickingOptions.pageNumber,
              pageSize: this.filterPickingOptions.pageSize,
              departmentTypeId: this.filterPickingOptions.departmentTypeId
            },
          })
          .then((response) => {
            const { data } = response
            const { items } = data
            if (vm.filterPickingOptions.pageNumber === 1) {
              vm.orders = []
            }
            _.forEach(items, function (value: Order) {
              vm.orders.push({
                id: value.id,
                date: value.date,
                documentNumber: value.documentNumber,
                departmentName: value.departmentName,
                employeeName: value.employeeName,
                statusCode: value.statusCode,
                statusName: value.statusName,
                note: value.note
              } as Order)
            });
            this.loaded = true
            this.dateLoaded = formatShortDateTime(new Date())
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getOrder(id: number) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .get(`/Orders/${id}`)
          .then((response) => {
            const { data } = response
            this.order = {
              id: data.id,
              date: data.date,
              documentNumber: data.documentNumber,
              departmentId: data.departmentId,
              departmentName: data.departmentName,
              employeeName: data.employeeName,
              statusCode: data.statusCode,
              statusName: data.statusName,
              note: data.note,
              details: _.map(data.detail, (x) => {
                return {
                  id: x.id,
                  orderId: x.orderId,
                  productId: x.productId,
                  productName: x.productName,
                  requestedQty: x.requestQty,
                  remainQty: x.remainQty,
                  approvedQty: x.approvedQty,
                  pickStockQty: x.pickStockQty,
                  reusedQty: x.reusedQty,
                  isConfirmed: x.isConfirmed,
                  price: 0,
                } as OrderDetail
              })
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getOrderDetails(id: number) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .get(`/OrderDetails/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    confirmOrderDetail(id: number, data: UpdateOrderDetailProperties) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .put(`/OrderDetails/checkOrderDetail/${id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    confirmOrder(id: number) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .put(`/Orders/completeOrder/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteOrder(id: number) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .get(`/Orders/deleteOrder/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    changeProduct( id: number,item: OrderDetail) {
      const data = {
        detail: item
      }
      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .put(`/OrderDetails/editOrderDetail/${id}`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
});
