import { defineStore } from 'pinia';
import axios from 'axios';

export interface UserInfo {
  firstname: string
}

export const useUserStore = defineStore('UserStore', {
  state: () => ({
    accessToken: localStorage.getItem('access_token')
  }),

  actions: {
    isAuthenticated() {
      return !!(
        localStorage.getItem("id_token") && localStorage.getItem("access_token")
      );
    },

    getInfo() {
      return new Promise<UserInfo | null>((resolve, reject) => {
        if (!this.isAuthenticated()) {
          resolve(null)
        } else {
          axios
            .get('https://identity.umphanghospital.go.th/connect/userinfo', {
              headers: {
                'Authorization': `Bearer ${this.accessToken}`,
              }
            })
            .then((response) => {
              const { data } = response
              resolve(data);
            })
            .catch((error) => {
              window.location.href = '/login'       
              reject(error)
            });
        }
      });
    },
  }
});
